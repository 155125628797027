import Repository, { apiUrl } from './Repository';

class LiveClassRepository {
    constructor(callback) {
        this.callback = callback;
    }

    async getLiveClassSession(id) {
        let url = `${apiUrl}/liveclass/oneSession?sessionId=${id}`;
        const reponse = await Repository.get(url)
            .then(response => {
                return response.data;
            })
            .catch(error => ({ error: JSON.stringify(error) }));
        return reponse;
    }

}

export default new LiveClassRepository();