import React, { Component } from 'react';

import './App.css';
import LiveClassRepository from "./repositories/LiveClassRepository";
import { getQuery, getCurrentUser } from './helper';

import { ZoomMtg } from '@zoomus/websdk';
const KJUR = require('jsrsasign')
ZoomMtg.setZoomJSLib('https://source.zoom.us/2.11.0/lib', '/av');
ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();
ZoomMtg.i18n.load('en-US');
ZoomMtg.i18n.reload('en-US');

let sdkKey = 'ExjTk0n4Sr2Pua0MofDteA';
let apiSecret = '5mMTWlZbXnuZaDcbxRWxPRfl5nkMWtCo';
let leaveUrl = 'https://questioncloud.in/dashboard/liveclass/';

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      meetingNumber: '',
      passWord: '',
      userName: '',
      userEmail: ''
    }
  }

  componentDidMount() {
    this.getSessionDetails();
  }

  getSessionDetails = async () => {
    let query = getQuery();
    let user = getCurrentUser(query.token);
    if (user && user.user) {
      this.setState({
        userName: user.user.name,
        userEmail: user.user.email
      });
      let response = await LiveClassRepository.getLiveClassSession(query.sessionId);
      if (response && response.liveClassSession) {
        this.setState({
          meetingNumber: response.liveClassSession.meetingNo,
          passWord: response.liveClassSession.meetingPassword,
        });
        this.getSignature(response.liveClassSession.meetingNo, response.liveClassSession.meetingPassword);
      }
    } else {
      window.location.href = leaveUrl
    }
  }

  getSignature(meetingNumber, passWord) {
    try {
      const iat = Math.round(new Date().getTime() / 1000) - 30
      const exp = iat + 60 * 60 * 2
      const oHeader = { alg: 'HS256', typ: 'JWT' }
      const oPayload = {
        sdkKey: sdkKey,
        appKey: sdkKey,
        mn: meetingNumber,
        role: 0,
        iat: iat,
        exp: exp,
        tokenExp: exp
      }
      const sHeader = JSON.stringify(oHeader)
      const sPayload = JSON.stringify(oPayload)
      const sdkJWT = KJUR.jws.JWS.sign('HS256', sHeader, sPayload, apiSecret)
      this.startMeeting(sdkJWT, meetingNumber, passWord);
    } catch (e) {
      console.log(e, 'dcdcd')
    }
  }

  startMeeting(signature, meetingNumber, passWord) {
    document.getElementById('zmmtg-root').style.display = 'block'
    ZoomMtg.init({
      leaveUrl: leaveUrl,
      success: (success) => {
        ZoomMtg.join({
          signature: signature,
          sdkKey: sdkKey,
          meetingNumber: meetingNumber,
          passWord: passWord,
          userName: this.state.userName,
          userEmail: this.state.userEmail,
          success: (success) => {
            console.log(success)
          },
          error: (error) => {
            console.log(error)
          }
        })
      },
      error: (error) => {
        console.log(error)
      }
    })
  }

  render() {
    return (
      <div className="App">
        <main>
          <h1>Zoom Meeting SDK Sample React</h1>
          <button onClick={this.getSignature}>Join Meeting</button>
        </main>
      </div>
    );
  }

}

export default App;