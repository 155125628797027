import axios from "axios";
export const baseurl = "https://questioncloud.in/liveapi";
// export const baseurl = "http://localhost:4003";
export const apiUrl = baseurl + "/userapi";

let local = typeof window !== 'undefined' ? localStorage : null;
if (local && local.usertoken) {
    axios.defaults.headers.common["x-auth-token"] = local.usertoken
}

export default {
    get: axios.get,
    post: axios.post,
    put: axios.put,
    delete: axios.delete
};
